<template>
    <section class="info">
        <header>
            <span class="title">{{item.name}}</span>
            <span v-if="item.to === 'personal-data' || item.to === 'company-settings'" class="update" @click="save = !save">
                save
            </span>
        </header>

        <article>
            <users v-if="item.to === 'users'" :update="save" />
            <personal-data v-else-if="item.to === 'personal-data'" :update="save" @edit="update"/>
            <company-settings v-else-if="item.to === 'company-settings'" :update="save" :orgInfo="orgInfo" @edit="update" />
            <Imports v-else-if="item.to === 'imports'" />
        </article>
    </section>
</template>

<script>
import axiosInstance from '@/services/AxiosTokenInstance';
import Users from './users/Index.vue'
import PersonalData from './personal-data/Index.vue'
import CompanySettings from './company-settings/Index.vue'
import Imports from './imports/Index.vue'
import { mapActions } from 'vuex'
import { UPDATE_USER_PROFILE_ACTION } from '@/store/storeconstants';
export default {
    props: {
        item: {
            type: Object,
            required: true,
            default: () => {}
        }
    },

    data() {
        return {
            orgInfo: localStorage.getItem("organization") ?  JSON.parse(localStorage.getItem("organization")) : {},
            save: false,
        }
    },

    components: {
        Users,
        PersonalData,
        CompanySettings,
        Imports
    },

    methods: {
        ...mapActions('account', {
          updateUserData : UPDATE_USER_PROFILE_ACTION,
        }),
        async update(data) {
            switch (this.item.to) {
                case 'personal-data':
                        await this.updateUserData(data).then(() => {
                            this.emitter.emit('alert', 'Profile Updated')
                        })
                    break;
                case 'company-settings':
                    this.updateCompany(data).finally(() => {
                        location.reload()
                    })
                    break;
            }
        },

        async updateCompany(data) {
            let bodyFormData = new FormData();
            bodyFormData.append('logo', data.logo);
            bodyFormData.append('name', data.name);
            bodyFormData.append('organization_id', data.id);

            const response = await axiosInstance.post(`update-organization`, bodyFormData,{
                headers : {
                    'Content-Type': 'multipart/form-data'
                }
            })

            if (FileReader && data.logo) {
                const fr = new FileReader();
                fr.readAsDataURL(data.logo);
                fr.onload = () => {
                    if (response) {
                        this.orgInfo.logo = fr.result
                        this.orgInfo.name = data.name
                        localStorage.setItem('organization', JSON.stringify(this.orgInfo));
                        this.emitter.emit('alert', 'Organization Updated')
                    }
                }


            }


        }
    }
}
</script>

<style lang="scss" scoped>
.info {
    background: white;
    max-height: 80vh;
    width: 80%;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;

    header {
        display: flex;
        justify-content: space-between;
        .title {
            text-transform: capitalize;
            font-weight: bold;
            font-size: 1.2rem;
        }

        .update {
            background: var(--primary-color);
            color: white;
            padding: 10px 20px;
            text-transform: uppercase;
            font-weight: 500;
            user-select: none;
            cursor: pointer;
        }
    }
}
</style>