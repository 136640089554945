<template>
    <div class="content">
        <div class="selector">
            <span :class="{active: isActive === 'active'}" @click="isActive = 'active'">Active</span>
            <span :class="{active: isActive === 'pending'}" @click="isActive = 'pending'">Pending Invitation</span>
        </div>
        <main>
            <Table :entries="usersList" :loaded="loaded"/>
            <div v-if="usersList.length" class="pagination" ref="pagination">
                <span>Showing <b>{{ currentPage === 1 ? 1 : currentPage - 1 + '1'}}-{{ currentTotal }}</b> from <b>{{total}}</b></span>
                <img src="/assets/images/left-arrow.svg" alt="next_page" @click="handlePage('left')">
                <img src="/assets/images/right-arrow.svg" alt="previous_page" @click="handlePage('right')">
            </div>
        </main>
    </div>
</template>

<script>
import axiosInstance from '@/services/AxiosTokenInstance';
import Table from './Table.vue'
export default {
    data() {
        return {
            orgInfo: localStorage.getItem("organization") ?  JSON.parse(localStorage.getItem("organization")) : {},
            per_page: 10,
            total: 0,
            last_page: 1,
            isActive: 'active',
            usersList: [],
            currentPage: 1,
            loaded: false,
        }
    },

    components: {
        Table
    },

    computed: {
        currentTotal() {
            return this.total > 10 && (this.per_page * this.currentPage < this.total) ? (this.per_page * this.currentPage) : this.total
        },
    },

    watch: {
        isActive: {
            immediate: true,
            handler() {
                this.total = 0
                this.last_page = 1
                this.currentPage = 1
                this.getData()
            }
        },
        currentPage() {
            this.getData()
        }

    },

    methods: {
        handlePage(to) {
            switch (to) {
                case 'left':
                    if (this.currentPage > 1)  this.currentPage -= 1
                    break;
                case 'right':
                    if (this.currentPage < this.last_page)  this.currentPage += 1
                    break;
            }
        },
        async getData() {
            this.loaded = false
            const response = await axiosInstance.get(`users-list?org_id=${this.orgInfo.id}&status_type=${this.isActive}&page=${this.currentPage}&limit=${this.per_page}`).finally(() => this.loaded = true)
            const responseData = response.data.data
            this.usersList = responseData.data
            this.total = responseData.total
            this.last_page = responseData.last_page
        }
    },
}
</script>

<style lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    .selector {
        display: flex;
        span {
            padding: 10px 15px;
            font-weight: 500;
            border: 1px solid #CAD6E2;
            user-select: none;
            transition: 0.2s;
            cursor: pointer;
            &.active {
                background: #0066FF10;
                color: #0066FF;
                border: 1px solid #0066FF;
            }

            &:first-of-type {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
            &:last-of-type {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }
}
.pagination{
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    gap: 5px;
    margin-bottom: 10px;
    img{
        width: 8px;
        cursor: pointer;
    }
    .underline{
        text-decoration: underline;
    }
  }
</style>