<template>
    <div class="imports">
        <header>
            <span class="active">Import Data</span>
        </header>
        <section>
            <label>
                <input type="file" accept=".csv" ref="file" @change="importFile">
                <figure>
                    <img src="/assets/images/img-icon.svg" alt="image_icon">
                </figure>
                <span>Click to upload your file</span>
            </label>
            <span class="description">You can upload a .csv file here to import new data directly. To do this create a sheet following the example given, export it to .csv and import here.</span>
            <span class="doc">Here is an example <a href="/assets/docs/Import Example.xlsx" download>doc</a>.</span>
            <span class="doc red">This should only be used as a last resource.</span>
            <label class="submit">
                <input type="submit" @click="file ? fileSelected = true : ''">
                <span>Submit</span>
            </label>
        </section>
    </div>
    <Popup v-if="fileSelected && file" :data="file" @close="handleClosePopup" />
</template>

<script>
import imports from '@/assets/json/imports.json'
import Popup from './popup.vue'
export default {
    data() {
        return {
            imports,
            activeImport: 0,
            file: undefined,
            org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
            fileSelected: false
        }
    },

    components: {
        Popup
    },

    methods: {
        importFile(e) {
            this.file = e.target.files[0];
        },

        handleClosePopup() {
            this.file = undefined
            this.$refs.file.value = ''
            this.fileSelected = false
        }
    },
    
}
</script>

<style lang="scss" scoped>
    header {
        display: flex;
        gap: 1rem;

        span {
            font-weight: 600;
            cursor: pointer;
            user-select: none;
            &.active {
                color: var(--primary-color);
            }
        }
    }

    section {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        label {
            font-size: inherit;
            font-weight: inherit;
            width: fit-content;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;
            background: var(--primary-color);
            color: white;
            cursor: pointer;
            position: relative;
            padding: 10px;
            box-sizing: border-box;
            border-radius: 2px;
            transition: 0.2s;

            &.submit {
                margin-top: 20px;
                border: solid 1px var(--primary-color);
                background: transparent;
                color: var(--primary-color);
            }

            &:hover {
                background: var(--primary-hover);
                color: white;
            }

            input {
                display: none;
            }

            figure {
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    -webkit-user-drag: none;
                    filter: invert(1);
                }
            }
        }

        .description {
            width: 60%;
            margin-top: 8px;
        }

        .doc {
            margin-top: 4px;
            a {
                color: var(--primary-color);
                text-decoration: underline;
            }

            &.red {
                color: $red;
            }
        }
    }
    
</style>