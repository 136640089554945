<template>
    <div class="imports-popup">
        <section v-click-outside="handleOutside">
            <header>
                <span>Importing File - {{ data.name.split('.')[0] }}</span>
                <span @click="handleOutside" class="close" />
            </header>
            <div class="data">
                <h3>{{ steps[activeStep].title }}</h3>
                <div class="bar">
                    <span class="progress" :style="{width: `${progress}%`}" />
                </div>
                <Constraints v-if="activeStep === 0" :constraints="constraints" :content="filteredContent" />
                <Upload v-else-if="activeStep === 1" :content="filteredContent" :head="filteredHead" :progress="steps[1].percentage" @progress="handleProgress" />
            </div>
            <footer>
                <span v-if="constraints.filter(el => el.success).length" :class="{grey: (activeStep === 1 && Math.round(progress) < 100)}" @click="handleNextButton">{{!activeStep ? 'next' : 'finish'}}</span>
                <span v-else @click="handleNextButton">Close</span>
            </footer>
        </section>
    </div>
</template>

<script>
import imports from '@/assets/json/imports.json'
import Constraints from '@/components/Ui/Imports/Steps/Constraints.vue'
import Upload from '@/components/Ui/Imports/Steps/Upload.vue'

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            imports,
            steps: [
                {
                    title: "Checking Constraints",
                    percentage: 5
                },
                {
                    title: "Uploading",
                    percentage: 95
                }
            ],
            activeStep: 0,
            head: [],
            content: [],
            filteredContent: [],
            filteredHead: [],
            constraints: [],
            progress: 0
        }
    },

    components: {
        Constraints,
        Upload
    },

    created() {
        this.sortData()

        window.addEventListener('keyup', this.handleEscape)
    },

    beforeUnmount() {
        window.removeEventListener('keyup', this.handleEscape)
    },

    methods: {
        sortData() {
            const reader = new FileReader();

            reader.onload = async (e) => {
                const text = e.target.result;
                const data = text.replaceAll(';', ',').split('\r\n').map(el => el.split(','))

                this.head = data[0]
                this.content = data.slice(1, data.length)

                this.checkConstraints()
            };
            reader.readAsText(this.data);
        },

        checkConstraints() {
            this.imports.forEach(item => {
                const constraints = item.constraints

                // missing columns on csv
                const missingItems = constraints.filter(item => this.head.indexOf(item) == -1);
                if (missingItems.length) {
                    this.constraints.push({
                        tag: item.tag,
                        success: false,
                        message: `Missing fields: ${missingItems.join(', ')}`
                    })
                    this.progress += this.steps[0].percentage / this.imports.length
                    return
                }

                //indexes to ignore on upload | unnecessary data
                const unnecessaryIndexes = []
                this.head.forEach((element, i) => {
                    if (!constraints.includes(element)) unnecessaryIndexes.push(i)
                })

                this.filteredHead.push({
                    tag: item.tag,
                    data: this.head.filter((element) => {
                        if (constraints.includes(element)) return true
                        return false
                    })
                }) 

                //content filtered by extraIndexes
                const content = []
                this.content.forEach(el => {
                    const data = el.filter((e, i) => {
                        if (!unnecessaryIndexes.includes(i)) return true
                        return false
                    })

                    if (data.length === constraints.length) content.push(data)
                })

                this.filteredContent.push({
                    tag: item.tag,
                    data: Array.from(new Set(content.map(JSON.stringify)), JSON.parse)
                })

                this.constraints.push({
                    tag: item.tag,
                    success: true
                })

                this.progress += this.steps[0].percentage / this.imports.length
            });
        },

        handleProgress(data) {
            this.progress += data
        },

        handleNextButton() {

            if (!this.constraints.filter(el => el.success).length) {
                this.$emit('close')
                this.activeStep = 0
                return
            }

            if (!this.activeStep) {
                this.activeStep = 1
                return
            }

            if (Math.round(this.progress) >= 100) {
                this.$emit('close')
                this.activeStep = 0
            }
        },

        handleEscape(e) {
            if (e.key === 'Escape' && (!this.activeStep || (this.activeStep && Math.round(this.progress) >= 100)) ) {
                this.$emit('close')
                this.activeStep = 0
            }
        },

        handleOutside() {
            if (!this.activeStep || (this.activeStep && Math.round(this.progress) >= 100)) {
                this.$emit('close')
                this.activeStep = 0
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .imports-popup {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 200;
        background: rgba(0,0,0,0.2);
        backdrop-filter: blur(1px);
        display: flex;
        justify-content: center;
        align-items: center;

        section {
            width: 95%;
            max-width: 600px;
            height: 95%;
            max-height: 350px;
            border-radius: 4px;
            box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.2);
            background: white;

            header {
                height: 4rem;
                padding: 20px;
                border-bottom: solid 1px $grey;
                font-weight: 600;
                font-size: 16px;
                display: flex;
                align-items: center;
                position: relative;

                .close {
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 4rem;
                    width: 4rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    &::before, &::after {
                        content: '';
                        width: 1rem;
                        height: 2px;
                        background: black;
                        position: absolute;
                    }

                    &::before {
                        transform: rotate(45deg);
                    }
                    &::after {
                        transform: rotate(-45deg);
                    }
                }
            }

            .data {
                height: calc(100% - 8rem);
                padding: 20px;

                h3 {
                    font-size: 12px;
                }

                .bar {
                    height: 12px;
                    background: $grey;
                    width: 100%;
                    border-radius: 4px;
                    display: flex;
                    overflow: hidden;

                    .progress {
                        height: 100%;
                        background: var(--primary-color);
                        transition: 0.1s;
                        display: flex;
                    }
                }
            }

            footer {
                height: 4rem;
                padding: 20px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                span {
                    background: var(--primary-color);
                    color: white;
                    padding: 10px 32px;
                    border-radius: 4px;
                    text-transform: capitalize;
                    cursor: pointer;
                    user-select: none;

                    &.grey {
                        background: $grey;
                    }
                }
            }
        }
    }
</style>