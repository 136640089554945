<template>
    <div class="content">
        <template v-for="(item, index) in data" :key="index">
            <template v-if="item.type === 'image'">
                <div class="item logo">
                    <label :for="item.label">{{ item.label }}</label>
                    <div class="import">
                        <label>
                            <input ref="importImage" type="file" accept="image/png, image/jpeg" @change="importImages" />
                            <template v-if="newLogo || imgURL + orgInfo.logo">
                                <figure>
                                    <img :src="newLogo
                                            ? newLogo
                                            : orgInfo.logo?.includes('data:image')
                                                ? orgInfo.logo
                                                : imgURL + orgInfo.logo
                                        " alt="" />
                                </figure>
                            </template>
                            <template v-else>Import Image</template>
                        </label>
                    </div>
                </div>
            </template>
            <template v-else-if="item.type === 'text' || item.type === 'email'">
                <div class="item">
                    <label>{{ item.label }}</label>
                    <input :type="item.type" :placeholder="item.label" v-model="formData[item.value]" />
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import data from "./data.json";
export default {
  props: {
    orgInfo: {
      type: Object,
      required: true,
      default: () => {},
    },
    update: {
      type: Boolean,
    },
  },

  data() {
    return {
      data,
      newLogo: "",
      formData: this.orgInfo,
      imgURL: process.env.VUE_APP_DO_SPACES,
    };
  },

  watch: {
    update() {
      this.$emit("edit", this.formData);
    },
  },

  methods: {
    importImages(event) {
      const file = event.target.files[0];

      if (FileReader && file) {
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = () => {
          this.formData.logo = file;
          this.newLogo = fr.result;

          this.$refs.importImage.value = "";
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    .item {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        width: 90%;
        max-width: 600px;

        &.logo {
            align-items: center;
        }

        .import {
            label {
                cursor: pointer;

                input {
                    display: none;
                }

                figure {
                    width: 150px;
                    height: 150px;
                    border-radius: 50%;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        label {
            font-weight: 600;
            margin: 0;
        }

        input {
            width: 100%;
            padding: 10px 5px;
            border-radius: 5px;
            background: #f5f5f6;
            border: 1px solid #cad6e2;
        }
    }
}
</style>