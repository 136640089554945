<template>
    <List :title="'Ticket'" :entries="bodyItems" :headItems="headItems" :hasCheck="true" :canCreate="canCreate"
        :loaded="loaded" :toFilter="toFilter" :tabFilters="tabFilters" :isActive="isActive" :total="total"
        :perPage="perPage" :currentPage="currentPage" :refresh="true" :is-ticket-list="true" :extra-actions="extraActions"
        :search-by="searchBy" :searching="searching" @optionSel="handleOption" @create="handleCreate"
        @filters="filterByItem" @actions="handleAction" @search="handleSearch" @refresh="getData(currentPage)"
        @searchTag="handleSearchBy" />
    <Pagination :entries="entries" :currentPage="currentPage" :total="total" :perPage="perPage" :pages="pages"
        @goToFirst="currentPage = 1" @goToLast="currentPage = pages" @goToPage="handlePage"
        @changePage="handlePageChange" />
    <edit-modal v-if="editItem" :response="response" :title="`Edit Ticket`" :items="toEdit"
        @close="; (editItem = false), (response = [])" @submitData=" editSite " />
    <CreateTicket v-if=" create " :title=" 'Create Ticket' " :response=" response " @create=" handleCreateTicket "
        @close=" create = false " />
    <delete-ticket v-if=" deleteTicket.length " :ticket=" deleteTicket[0] " @close=" handleDelete " />
    <AssignBox v-if=" toAssign.length " :tickets=" toAssign " @close=" handleCloseAssign " />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";
import axiosInstance from "@/services/AxiosTokenInstance";
import nodeInstance from '@/services/NodeServiceinstance';
import List from "@/components/List/Index.vue";
import Pagination from "@/components/List/Pagination.vue";
import timeSince from "@/mixins/timeSince";
import isMobile from "@/mixins/isMobile";
import EditModal from "@/components/Ui/CRUD/Edit.vue";
import CreateTicket from "@/components/Create/Tickets/Index.vue";
import api from "@/mixins/optionsApi";
import DeleteTicket from "@/views/remote_support/pages/Overview/Delete.vue";
import AssignBox from '@/components/Ui/Ticket/AssignBox.vue'

export default {
    title() {
        return `Remote Assistance | ${this.title}`;
    },
    data() {
        return {
            title: "Tickets",
            entries: [],
            currentPage: 1,
            filteredEntries: [],
            total: 0,
            pages: 0,
            perPage: 10,
            imgURL: process.env.VUE_APP_DO_SPACES,
            loaded: false,
            filter: [],
            canSee: [4, 5, 6, 7, 8],
            canCreate: [4, 5, 6, 7, 8, 9, 10],
            bodyItems: [],
            editOptions: ["see"],
            search_key: "",
            hasOptions: true,
            see: false,
            editItem: false,
            create: false,
            selItem: 0,
            client: localStorage.getItem("client")
                ? JSON.parse(localStorage.getItem("client"))
                : null,
            org: localStorage.organization ? JSON.parse(localStorage.organization) : undefined,
            user: localStorage['U_P'] ? JSON.parse(localStorage['U_P']) : {},
            tabFilters: [
                {
                name: "All Tickets",
                filter: "",
                isActive: true,
                },
            ],
            isActive: 0,
            no: false,
            response: [],
            status: [],
            users: [],
            deleteTicket: [],
            refresh: false,
            ticketUnreads: [],
            extraActions: [{name: 'assign to'}],
            searchBy: [
                {
                    name: 'Search Tickets' ,
                    tag:'tickets'
                },
                {
                    name: 'Search Messages' ,
                    tag:'chats'
                }
            ],
            searching: 'tickets',
            toAssign: [],
            interval: undefined
        };
    },

    provide() {
        return {
        edit: this.editOptions,
        hasOptions: this.hasOptions,
        };
    },

    components: {
        List,
        Pagination,
        EditModal,
        CreateTicket,
        DeleteTicket,
        AssignBox
    },

    mixins: [timeSince, isMobile, api],

    watch: {
        getUserProfile: {
            immediate: true,
            handler(val) {
                if (val.id !== '') {
                    this.getData(val);
                }
            }
        },
        currentPage: {
            handler(val) {
                if (this.searching === 'chats') {
                    this.getChats(val);
                    return
                }
                this.getData(val);
            },
        },

        filter() {
            if (this.currentPage !== 1) {
                this.currentPage = 1
                return
            }
            this.getData(1);
        },

        entries() {
            this.getUnreads()
        },

        searching(val) {
            if (this.currentPage !== 1) {
                this.currentPage = 1
                return
            }

            if (val === 'chats') {
                this.getChats(1);
                return
            }
            this.getData(1);
        },

        search_key(val) {
            if (!val.length) {
                this.searching = 'tickets'
                return
            }

            if (this.currentPage !== 1) {
                this.currentPage = 1
                return
            }
            this.getData(1);
        },
    },

    computed: {
        ...mapGetters("account", {
            getUserProfile: GET_USER_PROFILE_GETTER,
        }),

        headItems() {
            if(this.searching === 'chats') {
                return ["Chat", "Ticket", "Sent"]
            }

            if ((this.getUserProfile.role?.id || 10) > 8) {
                return ["Ticket", "Product"]
            }

            return ["Ticket", "Product", "Client | Site", "Assigned To", "Priority", "Status"]
        },

        currentTotal() {
            return this.total > 10 && this.perPage * this.currentPage < this.total
                ? this.perPage * this.currentPage
                : this.total;
        },

        toFilter() {
            const toReturn = [
                {
                    name: "priority",
                    options: [
                        {
                        id: 1,
                        icon: "/assets/images/low_flag.svg",
                        name: "low",
                        },
                        {
                        id: 2,
                        icon: "/assets/images/medium_flag.svg",
                        name: "medium",
                        },
                        {
                        id: 3,
                        icon: "/assets/images/high_flag.svg",
                        name: "high",
                        },
                    ],
                },
                {
                    name: "status",
                    options: this.status,
                },
                {
                    name: "sites",
                    options: this.api('sites'),
                },
            ];

            if(this.canSee.includes(this.getUserProfile.role.id)) toReturn.push({
                    name: "clients",
                    options: this.api("clients"),
            })
            return toReturn
        },

        toEdit() {
            const item = this.entries.find((el) => el.id === this.selItem) || {};
            if (Object.keys(item).length) {
                let toReturn = [
                {
                    type: "text",
                    label: "Name",
                    value: item.name,
                },
                {
                    type: "select",
                    label: "Country",
                    options: this.api("countries"),
                    value: {
                    id: item.country_id,
                    name: item.country_name ? item.country_name : "Test",
                    },
                },
                {
                    type: "multiple-select",
                    label: "Products",
                    options: this.api("products"),
                    value: item.product_list.length
                    ? item.product_list
                        .filter((el) => el.products_details)
                        .map((prod) => {
                            return {
                            rel_id: prod.id,
                            id: prod.id_product,
                            name: prod.products_details.name,
                            image: null,
                            };
                        })
                    : [],
                },
                {
                    type: "multiple-select",
                    label: "Users",
                    options: this.api("users"),
                    value: item.users.length
                    ? item.users.map((user) => {
                        return {
                            rel_id: user.id,
                            id: user.id_user,
                            name: user.user_details.name,
                            image: user.user_details.avatar
                            ? this.imgURL + user.user_details.avatar
                            : null,
                        };
                        })
                    : [],
                },
                {
                    type: "select",
                    label: "Status",
                    options: [
                        { id: 2, name: "Inactive" },
                        { id: 1, name: "Active" },
                    ],
                    value: {
                        id: item.status === 1 ? 1 : 2,
                        name: item.status === 1 ? "Active" : "Inactive",
                    },
                },
                ];

                if (this.getUserProfile.role.id !== 9) {
                    const toAdd = {
                        type: "select",
                        label: "Client",
                        options: this.api("clients"),
                        value: item.client
                        ? item.client.client_details
                            ? {
                                id: item.client.client_details.id,
                                name: item.client.client_details.name,
                                image: item.client.client_details.logo
                                ? this.imgURL + item.client.client_details.logo
                                : null,
                            }
                            : []
                        : [],
                    };
                    toReturn.splice(2, 0, toAdd);
                }
                return toReturn;
            }
            return [];
        },

        productsToSend() {
            let toReturn = [];
            const item = this.entries.find((el) => el.id === this.selItem) || {};
            const prod_names = item.product_list
                .filter((el) => el.products_details)
                .map((el) => el.products_details.name);
            prod_names.forEach((el) => toReturn.push({ name: el }));
            return toReturn;
        },
    },

    created() {
        this.getStatus()
        this.usersToSend()

        this.emitter.on('reload-tickets', () => {
            this.getData(this.currentPage)
        })

        this.emitter.on("new-message", (data) => {
            const receivers = data.receiverUsers.map(el => el.userId)
            if (receivers.includes(this.user.id)) {
                this.getUnreads()
            }
        });
    },

    mounted() {
        this.interval = setInterval(() => {
            this.getData(this.currentPage)
        }, 60000);
    },

    beforeUnmount() {
        clearInterval(this.interval)
    },

    methods: {
        ...mapActions({
            storeAnalytics: 'analytics/storeAnalytics',
        }),

        handlePage(to) {
            this.currentPage = to
        },

        handlePageChange(to) {
            switch (to) {
                case "less":
                this.currentPage -= 1;
                break;
                case "more":
                this.currentPage += 1;
                break;
            }
        },

        async getStatus() {
            const response = await axiosInstance.get("ticket-status-list");
            this.status.push(...response.data.data);
        },

        async usersToSend() {
            this.users = await this.api("users");
        },

        async getUnreads() {
            const tickets = this.entries.map(el => el.id)
            if (tickets.length) {
                const response = await nodeInstance.get(`/chats/ticketUnreadCounts?userId=${this.user.id}&ticketIds=${tickets}`)
                if (response.status !== 200) {
                    this.ticketUnreads = []
                }
                this.ticketUnreads = response.data
            }
            
            this.fillBodyItems()
        },

        fillBodyItems() {
            this.bodyItems = this.entries.map((item) => {
                this.loaded = true
                if(this.searching === 'chats') {
                    return [
                        {
                            notAssigned: false,
                            messages: false,
                            closed: false,
                            template: "normal",
                            id: item.ticketId,
                            data: item.searchData.description
                        },
                        {
                            template: "normal",
                            data: `${item.ticketDetails?.title} #${item.ticketId}`,
                        },
                        {
                            template: "normal",
                            data: this.timeSince(item.createdAt),
                        }
                    ]
                }
                
                let toReturn = [
                    {
                        notAssigned: !item.assigned_users.length,
                        messages: this.ticketUnreads.length ? this.ticketUnreads.find(el => el.ticketId === item.id)?.chatCount : 0,
                        closed: item.ticket_status.status >= 5,
                        template: "image",
                        id: item.id,
                        data: {
                            image: item.product.product_images.length ? this.imgURL + item.product.product_images[0].image : item.product.category?.category_details?.images.length ? this.imgURL + item.product.category.category_details.images[0].image  : null,
                            title: `${item.title} #${item.id}`,
                            description: [
                                {
                                    light: false,
                                    text: item.ticket_raised?.id !== item.created_by?.id ? 'Created by Organisation' : 'Created by Client',
                                },
                                {
                                    light: false,
                                    text: item.created_by?.name,
                                },
                                {
                                    light: true,
                                    text: `created ${this.timeSince(item.created_at)}`,
                                },
                            ],
                        },
                    },
                    {
                        template: "multiple-rows",
                        data: [
                            {
                                text: item.product?.category?.category_details?.name || "NaN",
                                light: true
                            },
                            {
                                text: item.product?.product_number || "NaN",
                                light: false
                            },
                            {
                                text: `${item.product?.name} | ${item.product?.serial_no}` || "NaN",
                                light: true
                            }
                        ] 
                    },
                    {
                        template: "multiple-rows",
                        data: [
                            {
                                light: false,
                                text: `${item.client?.name || ''} | ${item.site?.name || ''} `,
                            }
                        ] 
                    }
                ]
                
                if (this.user.role.id < 9) {
                    toReturn.push(
                        {
                            template: "assign",
                            id: item.id,
                            data: item.assigned_users,
                        }
                    )

                    toReturn.push(
                        {
                            template: "priority",
                            id: item.id,
                            data: item.ticket_priority,
                        }
                    )

                    toReturn.push(
                        {
                            template: "status",
                            item: item,
                            data: item.ticket_status,
                            options: this.status,
                            users: this.users
                        }
                    )
                }
                return toReturn
            });
        },

        async getData() {
            this.loaded = false;
            this.entries = [];
            this.perPage = 0;
            this.total = 0;
            this.pages = 0;
            const client_id = this.client ? this.client.id : "";
            const site = this.getUserProfile.site?.id_project || ''

            if (!this.org) {
                return
            }

            let url = `ticket-list?org_id=${this.org.id}&search_key=${this.search_key}&site_id=${site}&client_id=${client_id}&page=${this.currentPage}`

            if (this.filter.length) url += `&${this.filter[0]}=${this.filter[1]}`

            const response = await axiosInstance.get(url)

            const responseData = response.data.data;
            this.entries = responseData.data;
            if (!responseData.data.length) this.loaded = true
            this.perPage = Number(responseData.per_page);
            this.total = responseData.total;
            this.pages = responseData.last_page;
        },

        async getChats(value) {
            this.loaded = false

            const response = await nodeInstance.get(`ticket-search/list?organizationId=${this.org.id}&searchKey=${this.search_key}&page=${value}`)

            if (response.status === 200) {
                this.entries = response.data.searchList
                this.total = response.data.totalCount;
            }
        },

        handleAction(data) {
            if (data.name === "delete selected") {
                data.data.forEach(async (element) => {
                    await axiosInstance.delete(`ticket/${element}`).finally(() => {
                        this.getData(this.currentPage);
                    });
                })
                return
            }

            if (data.name === "assign to") {
                this.toAssign = this.entries.filter(el => data.data.includes(el.id))
            }
        },

        handleCloseAssign() {
            this.toAssign = []
            this.getData(this.currentPage)
        },

        filterByItem(data) {
            switch (data[0]) {
                case "priority":
                    this.filter = ["priority", data[1]];
                break;
                case "status":
                    this.filter = ["status", data[1]];
                break;
                case "sites":
                    this.filter = ["site_id", data[1]];
                break;
                case "clients":
                    this.filter = ["client_id", data[1]];
                break;
                case "remove":
                    this.filter = [];
                break;
            }
        },

        handleCreate(value) {
            this.create = value;
        },

        async handleCreateTicket(data) {
            const toSend = {
                id_organization: this.org.id,
                title: data.title,
                description: data.description,
                id_project: data.site?.id || null,
                id_product: data.product?.id || null,
                id_user: this.user.id,
                ticket_questions: data.answers ? data.answers.filter(el => el.id).map(el => {
                return {
                    q_id: el.q_id,
                    a_id: el.id
                }
                }) : []
            };

            if (this.canSee.includes(this.getUserProfile.role.id)) toSend.create_for = 'client'

            const response = await axiosInstance.post(`add-ticket`, toSend);

            if (response.status === 200) {

                let client = this.client?.id?.toString() || ''

            
                if (data.site && this.canCreate.includes(this.getUserProfile.role.id)) {
                    const res = await axiosInstance.post(`client-by-site`, {site_id: data.site.id})
                    client = res.data.data.client_id
                }

                const analyticsInfo = {
                    action: "create-ticket",
                    environment: process.env.VUE_APP_ENVIRONMENT,
                    org_id: this.org.id.toString(),
                    ticket_id: response.data.data?.ticketId?.toString(),
                    user_id: this.user.id?.toString(),
                    product_id: data.product?.id?.toString(),
                    category_id: data.product?.category?.id?.toString(),
                    site_id: data.site?.id?.toString(),
                    client: client.toString(),
                    priority_id: '1',
                    created_by: this.canSee.includes(this.getUserProfile.role.id) ? 'org' : 'client',
                    device: 'Desktop',
                    is_client_user: this.canSee.includes(this.getUserProfile.role.id)
                }

                this.storeAnalytics(analyticsInfo)

                this.response = [true, true]

                this.create = false

                const toCreateRoom = {
                    type: "ticket",
                    ticketId: response.data.data.ticketId,
                    organizationId: this.org.id,
                }

                if (this.canSee.includes(this.getUserProfile.role.id)) toCreateRoom.ownerId = response.data.data.userId

                this.emitter.emit("create-room", toCreateRoom);

                this.emitter.emit("alert", response.data.message);

                this.getData(this.currentPage)
            }
            else {
                this.response = [true, false]
                this.emitter.emit('alert', response.data.message)
            }
        },

        handleAlert(data) {
            this.emitter.emit("alert", data);
        },

        handleOption(data) {
            this.selItem = data[1];
            switch (data[0]) {
                case "see":
                    this.$router.push({ path: `/remote-support/tickets/${this.selItem}` });
                break;
                case "delete":
                    this.deleteTicket.push(this.entries.filter(el => el.id === this.selItem)[0])
                break;
            }
        },

        handleSearch(data) {
            this.search_key = data;
        },

        async handleDelete(value) {
            if (value) {
                const response = await axiosInstance.delete(`ticket/${this.selItem}`)
                this.getData(this.currentPage)
                this.emitter.emit("alert", response.data.message);
            }

            this.deleteTicket = [];
            this.selItem = undefined
        },

        handleSearchBy(tag) {
            this.searching = tag
        }
    },
    };
</script>