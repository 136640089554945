<template>
    <div class="table-container">
        <div class="table-head">
            <div class="main-items">
            <span>User</span>
            <span>Login</span>
            </div>
        </div>
        <section>
            <template v-if="entries.length && loaded">
                <template v-for="(entry, index) in entries" :key="index">
                    <User :user="entry" />
                </template>
            </template>
            <template v-else-if="!entries.length && loaded">
                <span class="empty">
                    There are currently no Users created.
                </span>
            </template>
            <template v-else>
                <div class="loading">
                    <span class="loader"></span>
                </div>
            </template>
        </section>
    </div>
</template>

<script>
import User from './User.vue'
export default {
    props: {
        entries: {
            type: Array
        },
        loaded: {
            type: Boolean
        }
    },
    components: {
        User
    }
}
</script>

.<style lang="scss" scoped>
.table-container {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    .table-head {
        background: #F5F5F6;
        .main-items {
            padding: 10px 20px;
            width: 100%;
            display: flex;
            span {
                width: calc(100% / 2);
                font-size: 0.8rem;
                font-weight: 500;
            }
        }
    }
    section {
        display: flex;
        flex-direction: column;
        border:solid 1px #F5F5F6;
        border-top: none;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

</style>