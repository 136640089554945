<template>
    <article>
        This File can:
        <template v-for="(item, i) in imports" :key="i">
            <div class="check-constraints" :title="constraints.find(el => el.tag === item.tag)?.success ? `Uploading ${item.tag}` : constraints.find(el => el.tag === item.tag)?.message">
                <div v-if="!constraints.find(el => el.tag === item.tag)" class="status loading">
                    <span class="loader" />
                </div>
                <div v-else class="status" :class="{success: constraints.find(el => el.tag === item.tag)?.success, error: !constraints.find(el => el.tag === item.tag)?.success}">
                    <span />
                </div>
                <span class="name">Import {{ item.name }} {{ content.find(el => el.tag === item.tag) ? `(${content.find(el => el.tag === item.tag).data.length} items)` : "" }}</span>
            </div>
        </template>
    </article>
</template>

<script>
import imports from '@/assets/json/imports.json'
export default {
    props: {
        constraints: {
            type: Array,
            default: () => []
        },
        content: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            imports,
            das: false
        }
    },

    methods: {
        checkStatus(item) {
            return this.constraints.find(el => el.tag === item.tag)?.success
        }
    }
}
</script>

<style lang="scss" scoped>

article {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .check-constraints {
        margin-top: 8px;
        display: flex;
        align-items: center;
        .status {

            &.loading {
                height: unset;
                background: unset;

                .loader {
                    width: 12px;
                    height: 12px;
                    border: 2px solid $grey;
                    border-top: 2px solid var(--primary-color);
                }
            }

            span {
                width: 12px;
                height: 12px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                &::after, &::before {
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 2px;
                }
            }

            &.success {
                span {
                    &::after, &::before {
                        background: green;
                    }

                    &::after {
                        transform: rotate(40deg);
                        width: 3px;
                        left: 3px;
                        bottom: 3px;
                    }

                    &::before {
                        width: 8px;
                        right: 0;
                        transform: rotate(-50deg);
                    }
                }
            }

            &.error {
                padding: 0;
                span {
                    &::after, &::before {
                        background: $red;
                    }

                    &::after {
                        transform: rotate(45deg);
                    }

                    &::before {
                        transform: rotate(-45deg);
                    }
                }
            }

            
        }

        .name {
            margin-left: 8px;
        }
    }
}
    
</style>