<template>
    <main class="company-settings">
        <Nav @selected="handleSelected"/>
        <Info :item="selectedItem"/>
    </main>
</template>

<script>
import Nav from './nav/Index.vue'
import Info from './info/Index.vue'
export default {
    components: {
        Nav,
        Info
    },

    data() {
        return {
            selectedItem: {}
        }
    },

    methods: {
        handleSelected(data) {
            this.selectedItem = data
        }
    }
}
</script>

<style lang="scss" scoped>
.company-settings {
    display: flex;
    gap: 3rem;
}
</style>