<template>
  <div class="table-row">
    <div class="item user">
      <figure v-if="user.avatar">
        <img :src="imgURL + user.avatar" alt="" />
      </figure>
      <div v-else class="no-avatar">
        <span>{{ user.name.charAt(0) }}</span>
      </div>
      <div class="info">
        <span>{{ user.name }}</span>
      </div>
    </div>
    <div class="item">
      <span v-if="user.last_login">
        {{
          internalInstance.appContext.config.globalProperties
            .$moment(user.last_login)
            .format("MMMM D, YYYY h:mm a")
        }}
      </span>
      <span v-else>unknown</span>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from "vue";
export default {
  props: {
    user: {
      type: Object,
    },
  },

  data() {
    return {
      imgURL: process.env.VUE_APP_DO_SPACES,
      internalInstance: getCurrentInstance(),
    };
  },
};
</script>

<style lang="scss" scoped>
.table-row {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: solid 1px #f5f5f6;
  user-select: none;
  &:last-of-type {
    border: none;
  }
  .item {
    width: calc(100% / 2);
    font-weight: 500;
    font-weight: 600;

    &.user {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      figure,
      .no-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
      }
      figure {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .no-avatar {
        background: #0066ff;
        color: white;
        font-weight: 500;
      }
    }
  }
}
</style>