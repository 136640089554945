<template>
    <article>
        <span v-if="Math.round(innerProgress) !== progress">Uploading Items</span>
        <span class="success" v-else>Items Uploaded! You can close this tab!</span>
        <span v-if="Math.round(innerProgress) !== progress" class="info">{{ message }}</span>
    </article>
</template>

<script>
import imports from '@/assets/json/imports.json'
import axiosInstance from '@/services/AxiosTokenInstance';
export default {
    props: {
        content: {
            type: Array,
            default: () => []
        },
        head: {
            type: Array,
            default: () => []
        },
        progress: {
            type: Number,
            default: () => 95
        },
    },

    data() {
        return {
            imports,
            org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
            innerProgress: 0,
            message: '',
            count: 0
        }
    },

    computed: {
        numberOfItems() {
            return this.content.map(el => el.data.length).reduce((a, b) => a + b)
        }
    },

    created() {
        this.uploadData()
    },

    methods: {
        async uploadData() {

            for (let i = 0; i < this.imports.length; i++) {
                const item = this.imports[i];
                
                const relations = item.relations.map(el => el.tag)

                const head = this.head.find(el => el.tag === item.tag)?.data || []
                const content = this.content.find(el => el.tag === item.tag)?.data || []

                for (let index = 0; index < content.length; index++) {
                    const element = content[index];
                    const toSend = {
                        org_id: this.org.id
                    }

                    for (let i = 0; i < element.length; i++) {
                        const el = element[i];
                        const headValue = head[i].replace(`${item.tag}_`, '')

                        if (relations.includes(head[i]) && el !== '') {
                            const value = await this.checkRelations(item, head[i], el.trim())
                            toSend[headValue] = value
                        }
                        else toSend[headValue] = el.trim()
                    }

                    this.count += 1

                    if (toSend.name === '') {
                        this.$emit('progress', this.progress / this.numberOfItems)
                        this.innerProgress += this.progress / this.numberOfItems
                    }
                    else {

                        this.message = `Uploading ${item.tag} - ${toSend.name} (${this.count}/${this.numberOfItems})`
                        if (item.tag === 'user') {
                            await this.handleUser(toSend, item.api)
                        }
                        else if (item.tag === 'client') {
                            await this.handleClient(toSend, item.api)
                        }
                        else if (item.tag === 'site') {
                            await this.handleSite(toSend, item.api)
                        }
                        else {
                            toSend.categories = [toSend.category]
                            const createItem = await axiosInstance.post(item.api, toSend)
                            this.emitter.emit("alert", createItem.data.message);
                            this.$emit('progress', this.progress / this.numberOfItems)
                            this.innerProgress += this.progress / this.numberOfItems
                        }
                    }
                }
            }            
        },

        async checkRelations(item, relation, name) {
            const relationItem = item.relations.find(el => el.tag === relation)

            const response = await axiosInstance.get(`${relationItem.api}?org_id=${this.org.id}&name=${name}`)
            if (response.status !== 200) {
                alert(response.data.errors)
            }

            return response.data.data
        },

        async handleClient(data, api) {
            const createItem = await axiosInstance.get(`${api}?org_id=${this.org.id}&name=${data.name}&country=${data.country}&email=${data.user_email}` )
            this.emitter.emit("alert", createItem.data.message);
            this.$emit('progress', this.progress / this.numberOfItems)
            this.innerProgress += this.progress / this.numberOfItems
        },

        async handleSite(data, api) {
            data.client_id = data.client_name
            if (data.product_serial_no) data.product_ids = [data.product_serial_no]
            const createItem = await axiosInstance.post(api, data)
            this.emitter.emit("alert", createItem.data.message);
            this.$emit('progress', this.progress / this.numberOfItems)
            this.innerProgress += this.progress / this.numberOfItems
        },

        async handleUser(data, api) {

            let toSend = new FormData();
            toSend.append("org_id", data.org_id);
            toSend.append("name", data.name || "");
            toSend.append("email", data.email || "");
            toSend.append("password", "123456");

            if (data.role < 9) {
                toSend.append("type", "organization");
                toSend.append("role_id", data.role);
            }
            else {
                toSend.append("type", "client");
                toSend.append("clients[0][client_id]", data.client_name);
                toSend.append("clients[0][role]", data.role);
                if(data.site_name !== '') {
                    toSend.append("clients[0][site]", data.site_name);
                }
            }

            const response = await axiosInstance.post(api, toSend)
            this.emitter.emit("alert", response.data.message);
            this.$emit('progress', this.progress / this.numberOfItems)
            this.innerProgress += this.progress / this.numberOfItems

        },
    }
}
</script>

<style lang="scss" scoped>
    article {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        span {
            &.success {
                color: green;
            }

            &.info {
                color: yellowgreen;
            }
        }
    }
</style>